import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function PrivacyPolicyPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();    
    const randomNumberInRange = (min:number, max:number) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
      <section data-bs-version="5.1" className="features5 cid-t3Pcr4WK0r" id="features5-4">
      <div className="container">
          <div className="row">
              <div className="col-12 col-lg-12">
                  
                  <h2 className="mbr-section-title mbr-fonts-style display-2"><strong>Privacy Policy</strong></h2>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className="mbr-iconfont mobi-mbri-update mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Information We Collect</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We may collect personal information such as your name, email address, shipping address, billing address, phone number, and payment details when you place an order with us or create an account on our website. We also collect non-personal information such as your IP address, browser type, and browsing patterns to improve our website's functionality and user experience</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className="mbr-iconfont mobi-mbri-camera mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>How We Use Your Information</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We use the information we collect to process and fulfill your orders, communicate with you about your purchases, provide customer support, and send you promotional offers and updates if you have opted in to receive them. We may also use your information to improve our products and services, personalize your shopping experience, and prevent fraudulent activities.</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-briefcase mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Data Security</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use encryption technology to safeguard your sensitive data during transmission and store it securely on our servers.</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-error mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Third-Party Disclosure</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We may share your personal information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, such as payment processors and shipping companies. These third parties are contractually obligated to maintain the confidentiality of your information and are prohibited from using it for any other purpose</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-github mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5">
                              <strong>Cookies</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and gather information about your preferences and interests. You can choose to disable cookies through your browser settings, but please note that some features of our website may not function properly if cookies are disabled</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-globe mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Your Rights</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              You have the right to access, correct, or delete the personal information we hold about you. You may also opt out of receiving promotional communications from us at any time by following the instructions provided in the emails we send you</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-globe-2 mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Changes to this Privacy Policy</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              We reserve the right to update or revise this Privacy Policy at any time. We will notify you of any changes by posting the new policy on this page. Your continued use of our website after the changes have been made constitutes your acceptance of the revised policy.</p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6">
                  <div className="content-card">
                      <div className="content-card-icon">
                          <span className=".mbr-iconfont mobi-mbri-users mobi-mbri"></span>
                      </div>
                      <div className="content-card-text">
                          <h4 className="mbr-card-title mbr-fonts-style display-5"><strong>Contact Us</strong></h4>
                          <p className="mbr-card-text mbr-fonts-style display-7">
                              If you have any questions or concerns about our Privacy Policy or how we handle your personal information, please contact us at 918266940320/mstraders0320@gmail.com. We are committed to addressing your inquiries promptly and transparently</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    )
})