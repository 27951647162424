import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useNavigate } from "react-router-dom";
export default observer(function ResponsiveFooterPage() {      
  const { commonStore,modalStore } = useStore();
  const currentyear = new Date().getFullYear();
  const navigate = useNavigate();
    return (
        <>
        <section data-bs-version="5.1" className="footer1 cid-u7PCAMkFWX" style={{once:"footers"}} id="footer1-1z">
<div className="container-fluid">
        <div className="media-container-row mbr-white">
            <div className="title-wrapper">
                <h4 className="mbr-section-title mbr-fonts-style display-5"><strong>MS Traders.</strong>
                </h4>
            </div>
            <div className="row row-links">
                <ul className="foot-menu mbr-fonts-style display-4">
                    <li className="foot-menu-item mbr-fonts-style">
                        <a onClick={()=>{                                                
                        navigate("/aboutus");
                    }} 
                    style={{cursor:'pointer'}} className="text-primary">About us</a>
                    </li>
                    <li className="foot-menu-item mbr-fonts-style">
                        <a style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/#features4-20");
                    }} className="text-primary">Our Collections</a>
                    </li>
                    <li className="foot-menu-item mbr-fonts-style">
                        <a onClick={()=>{
                        navigate("/contactus");
                    }} style={{cursor:'pointer'}} className="text-primary">Contact Us</a>
                    </li>
                    <li className="foot-menu-item mbr-fonts-style">
                        <a onClick={()=>{
                        navigate("/terms");
                    }} style={{cursor:'pointer'}} className="text-primary">Terms Conditions</a></li>
                    <li className="foot-menu-item mbr-fonts-style">
                        <a style={{cursor:'pointer'}} onClick={()=>{
                        navigate("/privacy");
                    }} className="text-primary">Privacy Policy</a>
                    </li>
                    <li className="foot-menu-item mbr-fonts-style">
                      <a onClick={()=>{
                        navigate("/refund");
                    }} style={{cursor:'pointer'}} className="text-primary">Refund Policy</a>
                  </li>
                    
                </ul>
            </div>
            <div className="row social-row">
                <div className="social-list">
                <div className="soc-item">
                        <a href="#" target="_blank">
                            <span className="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div><div className="soc-item">
                        <a href="#" target="_blank">
                            <span className="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div><div className="soc-item">
                        <a href="#" target="_blank">
                            <span className="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div><div className="soc-item">
                        <a href="#" target="_blank">
                            <span className="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div></div>
            </div>
        </div>
        <div className="copyright">
            <p className="mbr-text mbr-fonts-style display-7">
                © <strong>Copyright {currentyear}&nbsp;MS Traders.</strong> All Rights Reserved.
            </p>
            <p className="mbr-text mbr-fonts-style display-7">
                Developed by <a href="https://trendydashboard.com/" target="_blank">Trendy Dashboard</a>
            </p>
        </div>
    </div>
</section>
        </>    
    )
})