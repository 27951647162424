import { observer } from 'mobx-react-lite';
import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import ModalContainer from '../../app/common/modals/ModalContainer';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../app/common/controls/Navbar';
import Content from '../../app/common/controls/Content';
export default observer(function AboutUsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
    return (
        <section data-bs-version="5.1" className="header6 cid-u7E16gcQJ5" id="header06-1s">
	

	
	<div className="mbr-overlay" style={{opacity:0.1,backgroundColor:'rgb(255, 255, 255)'}}></div>

	<div className="container">
		<div className="row">
			<div className="col-12 col-lg-12">
				<div className="card-wrapper">
					<h3 className="mbr-section-cardtitle mbr-fonts-style mb-2 display-5">
						<strong>About Us</strong></h3>
					<p className="mbr-cardtext mbr-fonts-style mb-0 display-7">
					Welcome to MS Traders, where fashion meets versatility and quality. We're a leading women's clothing company dedicated to providing stylish and sophisticated attire for every occasion. Our journey began with a vision to redefine contemporary fashion, empowering women to express their unique personalities through timeless pieces crafted with care and precision.
					</p><br/>
                    <h3 className="mbr-section-cardtitle mbr-fonts-style mb-2 display-5">
                    <strong>What Sets Us Apart</strong></h3>
                    <p className="mbr-cardtext mbr-fonts-style mb-0 display-7">
					    At MS Traders, we pride ourselves on offering a curated selection of <strong>Kaftan,Co Ords,Indo Western,Fit and Flare</strong> that meets the highest standards of quality and craftsmanship. Each product in our collection is handpicked by our team of experts who are dedicated to sourcing the best of the best.
					</p><br/>
                    <p className="mbr-cardtext mbr-fonts-style mb-0 display-7">But we're not just about products; we're about people. Our customer-centric approach means that we go above and beyond to ensure that every interaction with us is nothing short of exceptional. Whether you have a question about a product, need assistance with your order, or simply want to share your feedback, our friendly and knowledgeable team is here to help.</p><br/>
                    <h3 className="mbr-section-cardtitle mbr-fonts-style mb-2 display-5">
                    <strong>Our Promise</strong></h3>
                    <p className="mbr-cardtext mbr-fonts-style mb-0 display-7">When you shop with us, you can shop with confidence. We stand behind every product we sell and are committed to your satisfaction. From our hassle-free shopping experience to our lightning-fast shipping and easy returns, we strive to make your experience with us as seamless as possible.</p><br/>
                    <h3 className="mbr-section-cardtitle mbr-fonts-style mb-2 display-5">
                    <strong>Join Our Community</strong></h3>
                    <p className="mbr-cardtext mbr-fonts-style mb-0 display-7">We believe that shopping is more than just a transaction; it's an opportunity to connect with like-minded individuals who share your passion for <strong>Kaftan,Co Ords,Indo Western,Fit and Flare</strong>. That's why we invite you to join our community of fellow enthusiasts, where you can share your experiences, exchange ideas, and be inspired.</p>
				</div>
			</div>			
		</div>
	</div>
</section>
    )
})