import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyCk0XHKwoA96KbhvD_cOUMx4XMoB4uM7Ds",
  authDomain: "ms-traders-4cc1a.firebaseapp.com",
  projectId: "ms-traders-4cc1a",
  storageBucket: "ms-traders-4cc1a.appspot.com",
  messagingSenderId: "11617599976",
  appId: "1:11617599976:web:5d1a01b2a621fbc541645d"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);