import { observer } from 'mobx-react-lite';
import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
import { useEffect, useState } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';
export default observer(function ContactUsPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
    return (
        <section data-bs-version="5.1" className="contacts1 cid-u7PCAL9KtX" id="contacts1-1y">
<div className="container">
    <div className="row">
        <div className="text-content col-12 col-lg-6 col-md-6">
            <h3 className="mbr-section-subtitle mbr-fonts-style display-4">
                <strong>FOLLOW US</strong>
            </h3>
            <h2 className="mbr-section-title mbr-fonts-style display-2">
                <strong>Follow Us</strong>
            </h2>
            <p className="mbr-text mbr-fonts-style display-7">
                Thank you for choosing <strong>MS Traders</strong>. We appreciate your business and look forward to serving you.</p>
        </div>
        <div className="col-12 col-lg-3 col-md-3 address-wrapper">
            <p className="mbr-address mbr-fonts-style display-5">
                <span className="mbr-iconfont mobi-mbri-map-pin mobi-mbri"></span>
                <strong>Address</strong>
            </p>
            <p className="mbr-text mbr-fonts-style display-7">
            15/1, Ground Floor, Shop No 3, Malviya Nagar, New Delhi - 110017
            </p>
        </div>
        <div className="col-12 col-lg-3 col-md-3 contact-wrapper">
            <p className="mbr-contact mbr-fonts-style display-5">
                <span className="mbr-iconfont mobi-mbri-responsive mobi-mbri"></span>
                <strong>Contacts</strong>
            </p>
            <p className="mbr-link mbr-fonts-style display-7">
<a href="mailto:mstraders0320@gmail.com">mstraders0320@gmail.com</a>
                </p>
            <p className="mbr-phone mbr-fonts-style display-4">
                <a href="telto:918266940320">+918266940320</a>                
            </p>
        </div>
    </div>
</div>
</section>
    )
})