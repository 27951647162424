import { observer } from "mobx-react-lite";
import { useState,useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { useStore } from "../../app/stores/store";
import ResponsiveCategoryPage from "./categories/ResponsiveCategoriesPage";
import AboutUsPage from "../../features/home/AboutUsPage";
import ResponsiveProductPage from "./categories/ResponsiveProductPage";

export default observer(function ResponsiveHomePage() {
    const [toggleBtn, setToggleBtn] = useState(true);
    const navigate = useNavigate();
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore,categoryStore,productStore} = useStore();    
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };  
  const {categoryByName,loadCategoryList,categoryreload,setCategoryReload} = categoryStore;
  const {productreload,productByName,loadProductList,setProductReload} = productStore;
  useEffect(() => {    
    if(categoryreload || categoryByName === undefined)
    {
        loadCategoryList().then(()=>{
            setCategoryReload(false);
        })
    }
    if(categoryByName !== undefined)
    {
        categoryByName.map((category,index)=>{
            
        })
    }
  },[categoryreload])
  useEffect(() => {    
    if(productreload || productByName === undefined)
    {
        loadProductList().then(()=>{
            setProductReload(false);
        })
    }
    if(productByName !== undefined)
    {
      productByName.map((product,index)=>{
            
        })
    }
  },[productreload])
    return (
        <>
        <section data-bs-version="5.1" className="features4 audiom4_features4 cid-u7PF8iKfB6 mbr-parallax-background" id="features4-20">
<div className="mbr-overlay" style={{opacity:0.4,backgroundColor:'rgb(0, 0, 0)'}}></div>
<div className="container">
    <div className="row">
        <div className="mbr-white col-md-12 col-lg-12">
            <div className="title-align align-center">
                <h3 className="mbr-section-subtitle mbr-semibold align-center mbr-light mbr-fonts-style display-4">Catchy Fashion</h3>
            </div>
            <h1 className="mbr-section-title align-center mbr-bold pb-5 mbr-fonts-style display-2">Your Fashion Our Passion</h1>
        </div>        
    </div>
</div>
</section>
<section data-bs-version="5.1" className="features03 forwardm5 cid-u8xaUrNzv7" id="features03-22">
<div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="items-wrapper">
                {!categoryreload && categoryByName.map((cat,key)=>(
                    <div className="item features-image">
                        <div className="item-wrapper">        
                    <div className="item-img">
                        <img src={cat.categoryImage} alt={cat.name} />                
                        <div className="item-content">                    
                        <div className="content-wrap">
                        <p className="item-role mbr-fonts-style display-4"><strong>
                        {cat.name}</strong></p>                                                             
                        </div>
                        </div>
                    </div>            
                    </div>
                    </div>
                ))}                
                </div>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-12">
                <div className="items-wrapper">
                {!productreload && productByName.map((product,key)=>(
                    <div className="item features-image">
                    <div className="item-wrapper">        
                <div className="item-img">
                    <img src={product.productImage} alt={product.name} />                
                    <div className="item-content">                    
                    <div className="content-wrap">
                    <p class="item-name mbr-fonts-style display-4" style={{cursor:'pointer'}} onClick={() => navigate(`product/${product.id}`)}>
                                            Quick View</p>
                    <p className="item-role mbr-fonts-style display-4"><strong>
                    {product.name}</strong></p>                                                             
                    </div>
                    </div>
                </div>            
                </div>
                </div>       
        ))}
                </div>
            </div>
        </div>
    </div>
</section>        
        </>
    )
})